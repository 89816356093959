import React, { FC, useEffect, useState } from 'react';
import {
    ImageContainer,
    Title,
    Year,
    MarqueeContainer,
    MarqueeItem,
    MarqueeDayContainer,
    MarqueeDayLabel,
    MarqueeBg,
} from './styled';
import { ReactComponent as Globe } from '../../../../../assets/icons/sas-globe.svg';
import { useTranslation } from 'react-i18next';
import Marquee from 'react-fast-marquee';

const MarqueeItemComponent: FC = () => {
    const { t } = useTranslation('pages/home');
    const [days, setDays] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);

    const deadline = 'October, 22, 2024';

    const getTime = (deadline: string) => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <MarqueeItem>
            <MarqueeDayContainer>
                {days}
                <MarqueeDayLabel>{t('days')}</MarqueeDayLabel>
            </MarqueeDayContainer>
            <MarqueeDayContainer>
                {hours}
                <MarqueeDayLabel>{t('hours')}</MarqueeDayLabel>
            </MarqueeDayContainer>
            <MarqueeDayContainer>
                {minutes}
                <MarqueeDayLabel>{t('minutes')}</MarqueeDayLabel>
            </MarqueeDayContainer>
            <MarqueeDayContainer>
                {seconds}
                <MarqueeDayLabel>{t('seconds')}</MarqueeDayLabel>
            </MarqueeDayContainer>
        </MarqueeItem>
    );
};

const HomeMarquee: FC = () => {
    const { t } = useTranslation('pages/home');

    return (
        <ImageContainer>
            <Title>
                {t('marquee-title-main')}
                <Year>{t('marquee-title-year')}</Year>
            </Title>
            <MarqueeContainer>
                <MarqueeBg />
                <Marquee direction="left" speed={50} gradient={false}>
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                    <Globe />
                    <MarqueeItemComponent />
                </Marquee>
            </MarqueeContainer>
        </ImageContainer>
    );
};

export default HomeMarquee;
