import React, { FC } from 'react';
import { HomepageRoot } from './styled';
import Intro from './components/intro/Intro';
import Marquee from './components/marquee/Marquee';
import Description from './components/description/Description';
import Opportunities from './components/opportunities/Opportunities';
import Computer from 'components/computer/Computer';
import Faq from './components/faq/Faq';
import Committee from './components/сommmittee/Committee';
import Speakers from './components/speakers/Speakers';
import Sponsors from './components/sponsors/Sponsors';
import Programs from './components/programs/Programs';

const HomePage: FC = () => (
    <HomepageRoot>
        <Intro />
        <Marquee />
        <Sponsors />
        <Description />
        <Computer />
        <Opportunities />
        <Committee />
        <Speakers />
        <Programs />
        <Faq />
    </HomepageRoot>
);

export default HomePage;
