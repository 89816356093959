import { HeaderMenu } from 'types';

export const headerMenuItemsEn: HeaderMenu[] = [
    { path: '/#programs', title: 'agenda', dataAtSelector: 'headerAgendaLink', isAnchor: true },
    { path: '/#speakers', title: 'speakers', dataAtSelector: 'headerSpeakersLink', isAnchor: true },
    { path: '/registration', title: 'registration', dataAtSelector: 'headerRegistrationLink' },
    { path: '/sponsors', title: 'sponsors', dataAtSelector: 'headerSponsorsLink' },
    { path: '/code', title: 'code', dataAtSelector: 'headerCodeLink' },
    { path: '/location', title: 'location', dataAtSelector: 'headerLocationLink' },
];
