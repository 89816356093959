import FlipCard from 'components/flip-card/FlipCard';
import GradientTitle from 'components/gradient-title/GradientTitle';
import Tags from 'components/tags/Tags';
import { UserBioBack } from 'components/user-bio/UserBioBack';
import { UserBioFront } from 'components/user-bio/UserBioFront';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiService, getStorageService } from 'services';
import { getSpeakersApiService } from 'services/api.factory';
import { DefaultTheme, withTheme } from 'styled-components';
import { Speaker, SpeakerTag, UserBio } from 'types';
import { ButtonContent, ButtonLabel } from '../intro/styled';
import { CommitteeContainer, ContentContainer } from '../сommmittee/styled';
import { BlockTitleContainer, MoreBtn, MoreBtnContainer } from './styled';
import { PaginationInput, PaginationOutput } from 'kl-b2c-ui-kit';

const BASE_FILTERS = { page: 0, size: 8, tagIds: [], pristine: true };

const Speakers: FC<{ theme: DefaultTheme }> = ({ theme }) => {
    const { t } = useTranslation('pages/home');
    const [activeSpeaker, setActiveSpeaker] = useState<string>();
    const [speakers, setSpeakers] = useState<PaginationOutput<Speaker> | null>(null);
    const [tags, setTags] = useState<SpeakerTag[] | null>(null);
    const [isFilterDirty, setIsFilterDirty] = useState<boolean>(false);
    const [config, setConfig] = useState<PaginationInput & { tagIds: string[]; pristine?: boolean }>(BASE_FILTERS);
    const storageService = getStorageService();
    const apiService = getApiService(storageService);
    const speakersApiService = getSpeakersApiService(apiService);

    useEffect(() => {
        const { page, size, tagIds, pristine } = config;

        speakersApiService.getAll({ page, size, tagIds }).then((response) => {
            if (pristine) {
                return setSpeakers(response.data);
            }

            let newItems: Speaker[] = [];

            if (speakers?.items) {
                newItems = [...speakers.items];
            }

            if (response.data?.items) {
                newItems = [...newItems, ...response.data.items];
            }

            setSpeakers({ count: response.data.count, items: newItems });
        });
    }, [config]);

    useEffect(() => {
        speakersApiService.getTags({ page: 0, size: 100 }).then((response) => setTags(response.data.items));
    }, []);

    if ((!speakers?.items?.length && !isFilterDirty) || !tags?.length) return null;

    return (
        <CommitteeContainer id="speakers">
            <BlockTitleContainer>
                <GradientTitle
                    variant="h1"
                    label={t('speakers-title')}
                    startColor="#29C2CC"
                    finishColor="#4AFFE9"
                    startLabelColor={theme.palette.black}
                    finishLabelColor={theme.palette.white}
                />
            </BlockTitleContainer>
            <Tags
                onChange={(chosen) => {
                    setConfig({ ...BASE_FILTERS, tagIds: chosen, pristine: true });
                    setIsFilterDirty(true);
                }}
                tags={tags.map(({ id, name }) => ({ key: id, label: name }))}
                singleChoose
            />
            <ContentContainer>
                {speakers?.items?.map(
                    ({ id, image, firstName, lastName, position, description, twitterNickName, nameOfReport }) => {
                        const bio: UserBio = {
                            name: `${firstName} ${lastName}`,
                            description: description || '',
                            image: image?.fileLink || '',
                            position,
                            twitter: twitterNickName,
                            nameOfReport,
                        };

                        return (
                            <FlipCard
                                id={`p${id}`}
                                key={id}
                                onClick={() => {
                                    if (!twitterNickName && !description) {
                                        return;
                                    }

                                    if (activeSpeaker === id) {
                                        setActiveSpeaker(undefined);
                                    } else {
                                        setActiveSpeaker(id);
                                    }
                                }}
                                preventFlip={!!twitterNickName || !!description}
                                isActive={id === activeSpeaker}
                                front={<UserBioFront {...bio} />}
                                back={<UserBioBack {...bio} />}
                            />
                        );
                    }
                )}
            </ContentContainer>
            {!!speakers?.count && !!speakers?.items?.length && speakers?.count > speakers?.items?.length && (
                <MoreBtnContainer>
                    {/*@ts-ignore*/}
                    <MoreBtn
                        type={'transparent'}
                        size={'large'}
                        onClick={() => {
                            setConfig((prevState) => ({
                                page: prevState.page === 0 ? 2 : prevState.page + 1,
                                size: 4,
                                tagIds: prevState.tagIds,
                                pristine: false,
                            }));
                        }}
                    >
                        <ButtonContent>
                            <ButtonLabel>{t('show-more-speakers')}</ButtonLabel>
                        </ButtonContent>
                    </MoreBtn>
                </MoreBtnContainer>
            )}
        </CommitteeContainer>
    );
};

export default withTheme(Speakers);
